import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
	selector: 'callback-from-auth',
	templateUrl: 'callback-from-auth.component.html',
	styleUrls: ['callback-from-auth.component.scss']
})

export class CallbackFromAuthComponent implements OnInit {

	constructor(private authService: AuthService) {

	}
	
	ngOnInit() { 
		this.authService.initAuth();
	}
}