import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';
import * as Sentry from "@sentry/angular";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private isDoneLoadAuthSuccess$ = new BehaviorSubject<boolean>(false);

    private doneAuthentication$ = new BehaviorSubject<number>(0);

    constructor(private oauthService: OAuthService) {
        this.oauthService.events.subscribe(event => {
            console.log(event.type);
            if ('token_refreshed' == event.type) {
                this.oauthService.loadUserProfile();
            }
        });
    }

    public hasValidAccessToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public initAuth(force: boolean = false, impersonate: string = undefined) {
        if (impersonate) {
            authConfig.customQueryParams = { 'acr_values': `${impersonate}` };
        } else {
            authConfig.customQueryParams = {};
        }
        this.oauthService.configure(authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        this.oauthService.loadDiscoveryDocumentAndTryLogin().then((_val) => {
            if (this.oauthService.hasValidAccessToken() && !force) {
                this.oauthService.loadUserProfile().then((user: any) => {
                    Sentry.setUser({ email: user.email, id: user.sub });
                    this.doneAuthentication$.next(1);
                    this.isDoneLoadAuthSuccess$.next(true);
                });
            } else {
                this.oauthService.initCodeFlow();
            }
        }).catch((err) => {
            console.log(err);
            this.doneAuthentication$.next(2);
            this.oauthService.initCodeFlow();
        });

        this.oauthService.setupAutomaticSilentRefresh();
    }

    // public initAuthSilent() {
    //     this.oauthService.configure(authConfig);
    //     this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    //     this.oauthService.loadDiscoveryDocument().then((val) => {
    //         if (this.oauthService.hasValidAccessToken()) {
    //             this.oauthService.loadUserProfile().then((user: any) => {
    //                 Sentry.setUser({ email: user.email, id: user.sub });
    //                 this.doneAuthentication$.next(1);
    //                 this.isDoneLoadAuthSuccess$.next(true);
    //             });
    //             return Promise.resolve();
    //         } else {
    //             return this.oauthService.tryLogin({
    //                 onLoginError(params) {
    //                     console.error('onLoginError', params);
    //                 },
    //                 onTokenReceived: (info) => {
    //                     debugger;
    //                     this.oauthService.loadUserProfile().then((user: any) => {
    //                         Sentry.setUser({ email: user.email, id: user.sub });
    //                         this.doneAuthentication$.next(1);
    //                         this.isDoneLoadAuthSuccess$.next(true);
    //                     });
    //                 }


    //             }).then((val) => {
                    
    //                 return Promise.resolve();

    //             });
    //         }
    //     }).catch((err) => {
    //         console.log(err);
    //         this.doneAuthentication$.next(2);
    //         this.oauthService.initCodeFlow();
    //     });
    // }

    public getIsDoneLoadAuthSuccess(): Observable<boolean> {
        return this.isDoneLoadAuthSuccess$.asObservable();
    }
    public getdoneAuthentication$(): Observable<number> {
        return this.doneAuthentication$.asObservable();
    }

    public logOut() {
        this.isDoneLoadAuthSuccess$.next(false);
        this.doneAuthentication$.next(0);
        this.oauthService.logOut();
    }

    public getUser(): any {
        return this.oauthService.getIdentityClaims() as any;
    }

    public getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    //public getObsLoadProfile() {

    //    if (this.identityClaims as any) return of(true);

    //    this.oauthService.events
    //        .pipe(filter(e => ['user_profile_loaded'].indexOf(e.type) > -1), map((t) => { return true; }));

    //}
}