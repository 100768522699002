import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextService } from '../context.service';

@Component({
    selector: 'az-confemailerror',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './confemailerror.component.html'
})
export class ConfEmailErrorComponent implements OnInit {

    public user$: Observable<any>;

    constructor(private contextService: ContextService) {
    }

    public async ngOnInit() {
        this.user$ = this.contextService.currentUser$;
    }
}