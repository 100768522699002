import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const authConfig: AuthConfig = {
    issuer: environment.issuer,
    sessionChecksEnabled: false,
    requireHttps: true,
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/callback-from-auth',
    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: 'gestprovefe',
    responseType: 'code',
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email offline_access roles impersonation gestprovefe_api',
    showDebugInformation: !environment.production,
    postLogoutRedirectUri: window.location.origin + '/'
    //timeoutFactor: 0.07 //TEST ONLY!!!
};