import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ContextService } from './context.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from './auth/auth.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  onAuthorizationResultSubscription: Subscription;
  onMessageErrorSubscription: Subscription;
  isDoneLoading: boolean = false;
  isModalShown: boolean = false;

  messageModal: string;
  @ViewChild('autoShownModal', { static: false })
  autoShownModal?: ModalDirective;

  constructor(private contextService: ContextService, private oauthService: AuthService,
    private router: Router) {
    // if (this.oidcSecurityService.moduleSetup) {
    //   this.doCallbackLogicIfRequired();
    // } else {
    //   this.oidcSecurityService.onModuleSetup.subscribe(() => {
    //     this.doCallbackLogicIfRequired();
    //   });
    // }
    setTheme('bs4'); //ngx-bootstrap
  }

  ngOnInit() {
    this.onMessageErrorSubscription = this.contextService.throwErrorMessage$.subscribe((message) => {
      this.messageModal = message;
      this.showModal();
    });
    
    //Da rivedere gestione del postloginurl 
    let url = localStorage.getItem('postLoginUrl');
    if (!url) {
      localStorage.setItem('postLoginUrl', window.location.pathname);
    }

    this.subscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd && e.url.indexOf('callback-from-auth') == -1 )).subscribe({ 
      next: () => {
        localStorage.setItem('postLoginUrl', window.location.pathname);
      }
    });


    this.oauthService.getIsDoneLoadAuthSuccess().pipe(distinctUntilChanged()).subscribe((v) => {
      this.isDoneLoading = v;
      if (this.isDoneLoading) {
        this.contextService.initUserContext()
        let url = localStorage.getItem('postLoginUrl');
        if(url) {
          this.router.navigate([url]);
        } else {
          this.router.navigate(["/"]);
        }
      } else {
        this.contextService.initGuestContext()
      }
    });

    if(this.oauthService.hasValidAccessToken()) {
      this.oauthService.initAuth();
    } 
    // else {
    //   this.oauthService.initAuthSilent();
    // }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.onAuthorizationResultSubscription.unsubscribe();
    this.onMessageErrorSubscription.unsubscribe();
    this.contextService.dispose();
  }

  // private doCallbackLogicIfRequired() {
  //   // Will do a callback, if the url has a code and state parameter.
  //   this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
  // }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    if (this.autoShownModal) {
      this.autoShownModal.hide();
    }
  }

  onHidden(): void {
    this.isModalShown = false;
  }
}
