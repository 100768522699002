import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ApiService, BaseFunctions } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextService } from '../context.service';
import { switchMap, tap, withLatestFrom, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../app.modalservice';
import { AuthService } from '../auth/auth.service';

declare var $;

@Component({
    selector: 'app-subtrials',
    templateUrl: './subtrials.component.html',
    styleUrls: ['./subtrials.component.css']
})

export class SubtrialsComponent implements OnInit, OnDestroy {

    public eventoDto: any;
    public mydogs: any;
    public enciLoiUrl$: Observable<any>;
    public lang$: Observable<any>;
    public owner$: Observable<any>;
    public user$: Observable<any>;
    public destroy$: Subject<boolean> = new Subject<boolean>();

    public modalSelectDogRef: BsModalRef | null;
    public modalContinueRef: BsModalRef | null;
    public modalWideRunnerRef: BsModalRef | null;
    public modalWarningRaggInfoRef: BsModalRef | null;
    public modalRequisitiRef: BsModalRef | null;

    public myDogsOpen = true;
    public conductedOpen = true;
    public disableContinue: boolean = false;

    public myRaggOpen = false;
    public currentragg: any = null;
    public sottoprovaSenzaRequisiti = null;

    @ViewChild('modalSelectDog', { static: false }) modalSelectDog: TemplateRef<any>;

    // Mappa
    public lat: number;
    public lng: number;
    public zoomControl: boolean = true;
    public scrollwheel: boolean = false;
    public disableDoubleClickZoom: boolean = true;
    public zoom: number = 15;
    public maxZoom: number = 21;
    public minZoom: number = 8;
    // Marker
    public mLabel: string = '';
    public mIconUrl: string = '/images/map-marker-ENCI.png';
    public isfrom: string = '';
    public nSingoli: string = "1";

    public nuovoRegolamentoLevrieri: boolean = false;
    public nuovaLicenzaCacilLevrieri: boolean = false;

    public chiediPrenotazionePosti: boolean = false;
    public prenotazioneSecondsValidity: number = 0;
    public postiPrenotatiUtente: number = 0;
    public maxCaniDaIscrivere: number = 0;

    public isTouchDevice = false;

    constructor(
        private apiService: ApiService,
        private contextService: ContextService,
        private route: ActivatedRoute,
        private base: BaseFunctions,
        private modalServiceBs: BsModalService,
        private router: Router,
        private modalService: ModalService,
        private oauthService: AuthService
    ) {
        this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public getIntegerPart(number) {
        return Math.floor(number);
    }

    public selectClass(t, sp, classe) {
        sp.RinuncioCacil = false;
        if (!sp.IsSelected) {
            sp.IsConduttoreDisabile = false;
            sp.IsConduttoreMinore = false;
        }
        this.enrollmentModified = true;
        this.uncheckOtherClasses(sp, classe);
        if (sp.CodSubKey == 'RACING') {
            if (classe.IsSelected) {
                classe.IscrittoComeWideRunner = false;
            } else {
                classe.IscrittoComeWideRunner = this.currentlev ? this.currentlev.WideRunner : false;
                //Apro il popup che mi permette di cambiare selezione
                this.modalWideRunnerRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true });
            }
        }
        this.spOthersAlreadyChecked(sp);
    }

    public contains(array: any[], item: any) {
        return array.some(s => s == item);
    }

    public showModalVetogene() {
        this.modalService.openModalVetogene();
    }

    public mancaConduttoreTelefono(listaspevento) {
        // Per coppie e mute i conduttori vengo specificati dopo
        if (listaspevento.filter(z => z.GpClassi.some(c => c.IsSelected) && !(z.CodSubKey == 'COPPIE' || z.CodSubKey == 'MUTE' || z.CodSubKey == 'BREVETTO DI MUTA')).length > 0) {
            return listaspevento.filter(z => z.GpClassi.some(c => c.IsSelected)).some(i => this.base.isNullOrEmpty(i.Conduttore) || this.base.isNullOrEmpty(i.Telefono));
        }
        return false;
    }

    public isClassSelected(sp) {
        return sp.GpClassi.some(c => c.IsSelected);
    }

    public mappa(lat, lng) {
        var win = window.open('https://maps.google.it/maps?f=d&saddr=Current+Location&daddr=' + lat.toString() + ',' + lng.toString(), '_blank');
        win.focus();
    }

    public async ngOnInit() {
        forkJoin(
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "LevrieriDataNuovoRegolamento2022" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "LevrieriDataNuovaLicenzaCacil2023" })
        ).subscribe(([datanuovoreglev, datanuovalicenzacacil]) => {
            let tempId = this.route.snapshot.params["id"];
            this.isfrom = this.route.snapshot.params["isfrom"];
            this.nSingoli = this.route.snapshot.params["nSingoli"];

            let ob$ = this.contextService.currentOwner$.pipe(
                withLatestFrom(this.contextService.currentLang$),
                switchMap(([owner, lang]) => {
                    let ownerId = owner ? owner.Id : -1;
                    return this.apiService.get("/Events/GetGpEventoDtoById", { idGpEvento: tempId, pageLanguage: lang, ownerId: ownerId });
                }),
                tap(res => {
                    if (res.dto.Coordinate && !this.lat && !this.lng) {
                        this.setCenterCoordinates(res.dto.Coordinate)
                    }
                }),
                takeUntil(this.destroy$)
            );

            ob$.subscribe(e => {
                this.eventoDto = e;
                this.nuovoRegolamentoLevrieri = (this.base.getDate(this.eventoDto.dto.DataDal) >= this.base.getDateFormStringDDMMYYYY(datanuovoreglev.dto, "/"));
                this.nuovaLicenzaCacilLevrieri = (this.base.getDate(this.eventoDto.dto.DataDal) >= this.base.getDateFormStringDDMMYYYY(datanuovalicenzacacil.dto, "/"));
                this.contextService.currentUser$.pipe(
                    withLatestFrom(this.contextService.currentLang$),
                    switchMap(([user, lang]) => {
                        if (user) {
                            // Chiedo di prenotare se:
                            // 1. Evento lo prevede
                            // 2. L'utente non ha prenotazioni attive
                            // 3, Ci sono ancora posti disponibili
                            this.chiediPrenotazionePosti = this.eventoDto.dto.FlPrenotazionePosti && !this.eventoDto.dto.Prenotazioni.some(p => p.IdUser == user.sub);
                            this.maxCaniDaIscrivere = this.eventoDto.dto.MaxCaniDaIscrivere;
                            return forkJoin([this.apiService.get("Users/GetMyDogs", { userGuid: user.sub, pageLanguage: lang, eventoId: this.eventoDto.dto.Id }),
                                this.apiService.get("Users/GetPrenotazioniAttiveByEventoAndUtente", { gpEventiId: tempId, userGuid: user.sub })]);
                        }
                        return of([null, null]);
                    }),
                    takeUntil(this.destroy$)
                ).subscribe(([dogs, pren]) => {
                    this.setPrenotazione(pren);
                    if (dogs != null && (dogs.dto.MyOwnedDogs.length > 0 || dogs.dto.MyConductedDogs.length > 0) && this.eventoDto.dto.IscrizioniAperte && !this.eventoDto.dto.IscrizioniChiuse) {
                        this.mydogs = dogs;
                        this.modalSelectDogShow(this.modalSelectDog);
                    }
                });
            });

            this.enciLoiUrl$ = this.apiService.get("Utils/GetPar", { par: "enciLoiUrl" });
            this.owner$ = this.contextService.currentOwner$;
            this.lang$ = this.contextService.currentLang$;
            this.user$ = this.contextService.currentUser$;

            // anchor links smooth scroll
            $('a[href^="#MappaEvento"]').on('click', function (event) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('#MappaEvento').offset().top
                }, 500);
            });
        });
    }

    public setPrenotazione(pren) {
        if (pren && pren.dto && pren.dto.length == 1 && pren.dto[0].SecondsValidity > 0) {
            this.prenotazioneSecondsValidity = pren.dto[0].SecondsValidity;
            this.postiPrenotatiUtente = pren.dto[0].NumPostiPrenotati;
        } else {
            this.prenotazioneSecondsValidity = 0;
            this.postiPrenotatiUtente = 0;
        }
    }

    public login() {
        this.oauthService.initAuth();
    }

    public uncheckOtherClasses(sp, classe) {
        sp.GpClassi.forEach(c => { if (c.Id != classe.Id) c.IsSelected = false });
    }

    public atLeastOneClassSelected(sottoProve) {
        let breakException = {};
        try {
            sottoProve.forEach(sp => {
                if (sp.GpClassi.some(c => c.IsSelected)) {
                    throw breakException;
                }
            });
        } catch (e) {
            return true;
        }
        return false;
    }

    public closeModalSelectDog() {
        this.modalSelectDogRef.hide();
        if (this.isfrom == 'edit') {
            this.router.navigate(['inscriptions']);
        }
    }

    public confirmPrenotazionePosti(numPostiPrenotati) {
        this.apiService.get("/Events/SetPrenotazionePostiByEventoAndUser", { gpEventiId: this.eventoDto.dto.Id, numPostiDaPrenotare: numPostiPrenotati }).subscribe(pren => {
            if (!pren.ex) {
                this.chiediPrenotazionePosti = false;
                this.setPrenotazione(pren);
            }
        });
    }

    public atLeastOneClassSelectedInProva(prova) {
        let breakException = {};
        try {
            if (prova.GpClassi.some(c => c.IsSelected)) {
                throw breakException;
            }
        } catch (e) {
            return true;
        }
        return false;
    }

    public modalSelectDogShow(t: TemplateRef<any>) {
        if (this.nSingoli == "0") {
            this.myDogsOpen = false;
            this.conductedOpen = false;
            this.myRaggOpen = true;
        } else {
            this.myDogsOpen = true;
            this.conductedOpen = false;
            this.myRaggOpen = false;
        }
        this.modalSelectDogRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-select-dog-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public currentdog: any = null;
    public currentlev: any = null;
    public currentlag: any = null;
    public enrollmentModified: boolean = false;

    public selectDogConfirmed(dog, owner, event, lang) {
        this.isfrom = '';
        this.currentragg = null;
        this.apiService.post("Payments/GetDogStatus", { Dog: dog, EventId: event.Id, Lang: lang }).subscribe(currentDogStatus => {
            this.currentdog = dog;
            this.currentdog.TagliaXProve = currentDogStatus.dto.TagliaCaneInDb; //Prendo il dato da DB dato che potrebbe essere stato modificato al volo senza salvare
            this.currentlev = currentDogStatus.dto.DatiLevriero;
            this.currentlag = currentDogStatus.dto.DatiLagotto;
            this.currentdog.mesiFineStagione = this.getMesiGiorno(this.base.sanitizeSingleDate(this.currentdog.DataNascita).split('-'), (new Date().getFullYear().toString() + '-12-31').split('-'));
            this.currentdog.RequisitiCane = currentDogStatus.dto.RequisitiCane;
            this.currentdog.SpConfigBloccateId = currentDogStatus.dto.SpConfigBloccateId;

            //Mostro il messaggio per contattare il CO in deroga solo se
            //1) Sono razza tuttelata
            //2) Sono in un'utna
            //3) Non esistono sottoprove con razza riservata del cane (GpSottoProve con IsSpeciale=1 e IdRazza=razza generica della razza del cane)
            //   In pratica cos� si vanno implicitamente a considerare solo sottoprove BH - VT
            this.currentdog.MostraMessaggioNoBhvtInDeroga = currentDogStatus.dto.IsRazzaTutelata && this.eventoDto.dto.CodEsRa == 'UTNA' &&
                !this.eventoDto.dto.SottoProve.some(sp => sp.IdRazza == this.currentdog.IdRazzaGenerica);

            event.SottoProve.forEach(sp => {
                //pulisco le variabili dall'eventuale giro precedente
                sp.Pagato = false;
                sp.PagatoDaAltri = false;
                let temp = new Date(this.base.sanitizeSingleDate(sp.DataScadenzaIscrizioni));
                let oggi = new Date(this.base.getToday());
                sp.IsIscrizioneScaduta = temp < oggi;

                sp.CaeOk = this.getCaeOk(dog, owner, sp);
                sp.SeguitaOk = this.getSeguitaOk(dog, owner, sp);

                //se c'� tra i pagamenti uno con questo idsottoprova, allora vuol dire che sono gi� pagati, da me o da altri
                if (currentDogStatus.dto.Payments.some(p => p.GpSottoProveId == sp.Id)) {
                    //se l'iduser del pagamento coincide con quello attuale scrivo "Pagato", altrimenti "Pagato da altri"
                    if (currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id && p.IdUser == owner.IdUser)) {
                        sp.Pagato = true;
                    }
                    else {
                        sp.PagatoDaAltri = true;
                    }
                    sp.Conduttore = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).Conduttore;
                    sp.Telefono = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).Telefono;
                    sp.RinuncioCacil = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).RinuncioCacil;
                    sp.RacingUltimoTempo = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).RacingUltimoTempo;
                    sp.RacingUltimaDistanza = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).RacingUltimaDistanza;
                    sp.IsConduttoreDisabile = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).IsConduttoreDisabile;
                    sp.IsConduttoreMinore = currentDogStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id).IsConduttoreMinore;
                } else {
                    sp.Pagato = false;
                    //aggiunto filtro anche per utente per evitare sovrapposizioni se pi� persone hanno lo stesso cane condotto e lo iscrivono per la stessa prova
                    let tempEnrollment = currentDogStatus.dto.Enrollments.find(p => p.GpSottoProveId == sp.Id && p.IdUser == owner.IdUser);
                    sp.GpClassi.forEach(cl => {
                        if (tempEnrollment && (cl.Id == tempEnrollment.GpClassiId)) {
                            cl.IsSelected = true;
                            cl.IscrittoComeWideRunner = tempEnrollment.IscrittoComeWideRunner;
                        } else {
                            cl.IsSelected = false;
                            cl.IscrittoComeWideRunner = false;
                        }
                        cl.IsSelectedAltro = false;
                        currentDogStatus.dto.Enrollments.forEach(enrol => {
                            // Stesso cane gi� associato in altro raggruppamento
                            if (enrol.GpSottoProveId == sp.Id
                                && enrol.IdCane == dog.Id
                                && enrol.IdUser == owner.IdUser
                                && enrol.RaggId > 0
                                && !cl.IsSelectedAltro) {
                                cl.IsSelectedAltro = true;
                            }
                        });

                    });
                    if (tempEnrollment) {
                        sp.Conduttore = tempEnrollment.Conduttore;
                        sp.Telefono = tempEnrollment.Telefono;
                        sp.RinuncioCacil = tempEnrollment.RinuncioCacil;
                        sp.RacingUltimoTempo = tempEnrollment.RacingUltimoTempo;
                        sp.RacingUltimaDistanza = tempEnrollment.RacingUltimaDistanza;
                        sp.IsConduttoreDisabile = tempEnrollment.IsConduttoreDisabile;
                        sp.IsConduttoreMinore = tempEnrollment.IsConduttoreMinore;
                    }
                }
            });
            event.SottoProve.forEach(sp => {
                this.spOthersAlreadyChecked(sp);
            });
            this.enrollmentModified = false;
            this.modalSelectDogRef.hide();
        });
    }

    public spOthersAlreadyChecked(parSp) {
        parSp.GiaIscrittoAltraSottoprova = false;
        parSp.GiaIscrittoStessaSottoprovaAltroRaggruppamento = false;
        // A. SEGUITA
        // Possibile solo una sottoprova al giorno
        // 03.01.24 LA non consideriamo il Brevetto di Muta su richiesta di Silvia Tortora
        if (parSp.CodProtSudd.indexOf('SGNA') > -1 && parSp.CodProtSudd.indexOf('SGNA_BRMU') == -1) {
            let altreSp = this.eventoDto.dto.SottoProve.filter(sp => (sp.DataGiorno == parSp.DataGiorno) && (sp.Id != parSp.Id) && (sp.CodProtSudd.indexOf('SGNA_BRMU') == -1));
            parSp.GiaIscrittoAltraSottoprova = altreSp.some(sp => sp.Pagato || sp.PagatoDaAltri || (sp.GpClassi.some(c => c.IsSelectedAltro)));
            parSp.GiaIscrittoStessaSottoprovaAltroRaggruppamento = parSp.Pagato || parSp.PagatoDaAltri || parSp.GpClassi.some(c => c.IsSelectedAltro && !c.IsSelected);
        }

        // B. TRACCIA E RIPORTO
        // Sottoprova riserva aperta solo se:
        // 1. non ci sono posti disponibili in altre sottoprove
        // 2. non ci sono gi� iscrizioni in altre sottoprove
        if (this.eventoDto.dto.IsEventoConRiserve && parSp.CodProtSudd == 'BACKUP') {
            parSp.GiaIscrittoAltraSottoprova = !this.eventoDto.dto.SpRiservaAperta || this.eventoDto.dto.SottoProve.some(sp => (sp.Id != parSp.Id) && (sp.Pagato || sp.PagatoDaAltri || (sp.GpClassi.some(c => c.IsSelected))));
        }
        // Possibile solo una sottoprova al giorno
        else if (parSp.CodProtSudd.indexOf('SUNA') > -1 && parSp.CodProtSudd.indexOf('SUNA_BASS') == -1) {
            parSp.GiaIscrittoAltraSottoprova = this.eventoDto.dto.SottoProve.some(sp => (sp.DataGiorno == parSp.DataGiorno) && (sp.Id != parSp.Id) && (sp.Pagato || sp.PagatoDaAltri || (sp.GpClassi.some(c => c.IsSelected))));
        }
        // Sottoprove non riserva abilitate solo se non iscritto in riserva
        else if (parSp.CodProtSudd.indexOf('SUNA') > -1 && this.eventoDto.dto.IsEventoConRiserve) {
            parSp.GiaIscrittoAltraSottoprova = this.eventoDto.dto.SottoProve.some(sp => (sp.CodProtSudd == 'BACKUP') && (sp.Pagato || sp.PagatoDaAltri || (sp.GpClassi.some(c => c.IsSelected))));
        }

        return true;
    }

    private getCaeOk(dog, owner, sp) {
        // LA 05.02.2025: utilizzato IsConduttoreCae come conduttore delle Forze dell'Ordine per le sole prove UTNA
        if (owner.IsConduttore || owner.IsIscrittoAlboAddestratori || dog.IdProprietario == owner.Id) { //Se sono conduttore o proprietario tutto OK
            return true;
        } else {
            if (sp.CodProtSudd.indexOf("UTNA") > -1 && owner.IsConduttoreCae) { //Non sono né conduttore né proprietario: se sono conduttore CAE e la sottoprova è UTNA tutto OK
                return true;
            } else {
                return false;
            }
        }
    }

    private getSeguitaOk(dog, owner, sp) {
        if (owner.IsConduttore || owner.IsIscrittoAlboAddestratori || dog.IdProprietario == owner.Id) { //Se sono conduttore o proprietario tutto OK
            return true;
        } else {
            if (sp.CodProtSudd.indexOf("SGNA") > -1 && owner.IsConduttoreCoppieMute) { //Non sono n� conduttore n� proprietario: se sono canettiere e la sottoprova � Seguita tutto OK
                return true;
            } else {
                return false;
            }
        }
    }

    public saveSelection(evento, owner, t, lang) {
        if (this.currentdog) {
            this.apiService.post("Dogs/SaveSelection", { SubTrials: evento.SottoProve, Dog: this.currentdog, Owner: owner, EventId: evento.Id, Lang: lang }).subscribe(ret => {
                if (ret.dto) {
                    let daTogliere = true;
                    evento.SottoProve.forEach(sp => {
                        if (sp.GpClassi.some(cl => cl.IsSelected)) {
                            daTogliere = false;
                        }
                    });
                    if (daTogliere) {
                        var index = this.eventoDto.dto.EnrolledDogsListS.indexOf(this.currentdog.Id);
                        if (index > -1) this.eventoDto.dto.EnrolledDogsListS.splice(index, 1);
                    } else {
                        if (this.eventoDto.dto.EnrolledDogsListS.indexOf(this.currentdog.Id) == -1) {
                            this.eventoDto.dto.EnrolledDogsListS.push(this.currentdog.Id);
                        }
                    }
                    //faccio aprire il modal solo se effettivamente il salvataggio � avvenuto
                    this.modalContinueRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-continue-ref' });

                } else {
                    var index = this.eventoDto.dto.EnrolledDogsListS.indexOf(this.currentdog.Id);
                    if (index > -1) this.eventoDto.dto.EnrolledDogsListS.splice(index, 1);
                    //per poter riprovare nel caso di errore o segnalazione dei controlli
                    this.disableContinue = false;
                }
                //this.modalContinueRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-continue-ref' });
            });
        } else {
            this.apiService.post("Dogs/SaveSelectionRagg", { SubTrials: evento.SottoProve, Ragg: this.currentragg, Owner: owner, EventId: evento.Id, Lang: lang }).subscribe(ret => {
                if (ret.dto) {
                    let daTogliere = true;
                    evento.SottoProve.forEach(sp => {
                        if (sp.GpClassi.some(cl => cl.IsSelected)) {
                            daTogliere = false;
                        }
                    });
                    if (daTogliere) {
                        this.currentragg.Soggetti.forEach(d => {
                            if (this.currentragg.Tipo == "C") {
                                var index = this.eventoDto.dto.EnrolledDogsListC.indexOf(d.CaniId);
                                if (index > -1) this.eventoDto.dto.EnrolledDogsListC.splice(index, 1);
                            }
                            if (this.currentragg.Tipo == "M") {
                                var index = this.eventoDto.dto.EnrolledDogsListM.indexOf(d.CaniId);
                                if (index > -1) this.eventoDto.dto.EnrolledDogsListM.splice(index, 1);
                            }
                        });

                    } else {
                        if (this.currentragg.Tipo == "C") {
                            this.currentragg.Soggetti.forEach(d => {
                                if (this.eventoDto.dto.EnrolledDogsListC.indexOf(d.CaniId) == -1) {
                                    this.eventoDto.dto.EnrolledDogsListC.push(d.CaniId);
                                }
                            });
                        }
                        if (this.currentragg.Tipo == "M") {
                            this.currentragg.Soggetti.forEach(d => {
                                if (this.eventoDto.dto.EnrolledDogsListM.indexOf(d.CaniId) == -1) {
                                    this.eventoDto.dto.EnrolledDogsListM.push(d.CaniId);
                                }
                            });
                        }
                    }
                    //faccio aprire il modal solo se effettivamente il salvataggio � avvenuto
                    this.modalContinueRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-continue-ref' });
                }
            });
        }
    }

    public classeNonSelPerLimitazioni(classe, limitazioni) {
        var ret = false;
        limitazioni.forEach(l => {
            if (l.Dettagli.some(d => d.GpSottoProveId == classe.GpSottoProveId && d.GpClassiId == classe.Id) && ((l.MaxSoggettiIscrivibili - l.PostiOccupati) <= 0)) {
                ret = true;
            }
        });
        return ret;
    }

    public isClassSelectedByCod(classi, cod) {
        return classi.some(z => z.CodClasse == cod && z.IsSelected);
    }

    public isClassExcluded(classe, dataGiorno, codProtSudd, codSubKey, sottoprovaId, dataNascitaMinima, nuovoRegolamentoLevrieri): boolean {
        classe.CodErrore = null;
        // Controllo GENERE
        if ((!classe.AbilitaM && this.currentdog.Sesso == 'M') || (!classe.AbilitaF && this.currentdog.Sesso == 'F')) {
            classe.CodErrore = "GENDER_NOT_ALLOWED";
            return true;
        }
        var mesiGiornoSp = this.getMesiGiorno(this.base.sanitizeSingleDate(this.currentdog.DataNascita).split('-'), this.base.sanitizeSingleDate(dataGiorno).split('-'));

        // Variabile per sapere se ho gi� effettuato i controlli sull'et�
        // del cane e non effettuare i controlli generici fatti alla fine
        let controlliMesiDaAEffettuati = false;

        //***********************************************************************************
        //************ CONTROLLI LEVRIERI VECCHIO REGOLAMENTO (AMNA_%) **********************
        //***********************************************************************************
        // Data inizio nuovo regolamento nella variabile globale LevrieriDataNuovoRegolamento2022
        if (codProtSudd.indexOf('AMNA_') == 0 && !nuovoRegolamentoLevrieri) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            //AVVIAMENTO - RAZZE PLI, WHIPPET, CIRNECO DELL'ETNA
            if ((codSubKey.indexOf('AVVIAMENTO') > -1)
                && (this.currentdog.IdRazza == 198 || this.currentdog.IdRazza == 204 || this.currentdog.IdRazza == 211)
            ) {
                let temp = this.base.isNullOrEmpty(this.currentdog.Loi)
                    || (mesiGiornoSp < 14)
                    || this.currentdog.mesiFineStagione > 107 //107: numero magico che mi dice se alla fine dell'anno (ossia della stagione) ha massimi 8 anni e 11 mesi
                    || ((codSubKey.indexOf('COURSING') > -1) && (this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                    || ((codSubKey.indexOf('RACING') > -1) && this.isLicenzaRacingCorretta());
                if (temp) {
                    if (this.base.isNullOrEmpty(this.currentdog.Loi)) {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 14) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (this.currentdog.mesiFineStagione > 107) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    } else {
                        classe.CodErrore = "LICENSING_ALREADY_OK";
                    }
                    return temp;
                }
            }

            //AVVIAMENTO - ALTRE RAZZE
            if ((codSubKey.indexOf('AVVIAMENTO') > -1)
                && (this.currentdog.IdRazza != 198)
                && (this.currentdog.IdRazza != 204)
                && (this.currentdog.IdRazza != 211)
            ) {
                let temp = this.base.isNullOrEmpty(this.currentdog.Loi)
                    || (mesiGiornoSp < 17)
                    || this.currentdog.mesiFineStagione > 107 //107: numero magico che mi dice se alla fine dell'anno (ossia della stagione) ha massimi 8 anni e 11 mesi
                    || ((codSubKey.indexOf('COURSING') > -1) && (this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                    || ((codSubKey.indexOf('RACING') > -1) && this.isLicenzaRacingCorretta());
                if (temp) {
                    if (this.base.isNullOrEmpty(this.currentdog.Loi)) {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 17) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (this.currentdog.mesiFineStagione > 107) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    } else {
                        classe.CodErrore = "LICENSING_ALREADY_OK";
                    }
                    return temp;
                }
            }

            //COURSING e RACING
            if ((codSubKey == 'COURSING') || (codSubKey == 'RACING')) {
                //CLASSE LICENZA - RAZZE PLI, WHIPPET
                if ((classe.CodClasse == 'LIC')
                    && (this.currentdog.IdRazza == 198 || this.currentdog.IdRazza == 204)
                ) {
                    let temp = (mesiGiornoSp < 15)
                        || (this.currentdog.mesiFineStagione > 107)
                        || (this.currentdog.mesiFineStagione > 36 && !this.isMisurazioniLevrieroCorrette(sottoprovaId)) //Se ha pi� di 2 anni (a fine anno) deve avere le due registrazioni di misurazione o la taglia settata
                        || ((codSubKey.indexOf('COURSING') > -1) && !(this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && !this.isLicenzaRacingCorretta());
                    if (temp) {
                        if (mesiGiornoSp < 15) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        else if (mesiGiornoSp >= 24 && !this.isMisurazioniLevrieroCorrette(sottoprovaId)) {
                            classe.CodErrore = "MISSING_MEASURES"
                        } else {
                            classe.CodErrore = "MISSING_LICENSING"
                        }
                        return temp;
                    }
                }

                //CLASSE LICENZA - RAZZE CIRNECO DELL'ETNA
                if ((classe.CodClasse == 'LIC')
                    && (this.currentdog.IdRazza == 211)
                ) {
                    let temp = (mesiGiornoSp < 15)
                        || (this.currentdog.mesiFineStagione > 107)
                        || ((codSubKey.indexOf('COURSING') > -1) && !(this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && !this.isLicenzaRacingCorretta());
                    if (temp) {
                        if (mesiGiornoSp < 15) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        } else {
                            classe.CodErrore = "MISSING_LICENSING"
                        }
                        return temp;
                    }
                }

                //CLASSE LICENZA - ALTRE RAZZE
                if ((classe.CodClasse == 'LIC')
                    && (this.currentdog.IdRazza != 198)
                    && (this.currentdog.IdRazza != 204)
                    && (this.currentdog.IdRazza != 211)
                ) {
                    let temp = (mesiGiornoSp < 18)
                        || (this.currentdog.mesiFineStagione > 107)
                        || ((codSubKey.indexOf('COURSING') > -1) && !(this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && !this.isLicenzaRacingCorretta());
                    if (temp) {
                        if (mesiGiornoSp < 18) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        } else {
                            classe.CodErrore = "MISSING_LICENSING"
                        }
                        return temp;
                    }
                }

                //CLASSE VETERANI
                if ((classe.CodClasse == 'VET')
                    && (this.currentdog.IdRazza == 198 || this.currentdog.IdRazza == 204)
                ) {
                    let temp = (mesiGiornoSp < 72)
                        || (this.currentdog.mesiFineStagione > 107)
                        || ((codSubKey.indexOf('COURSING') > -1) && !(this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && !this.isLicenzaRacingCorretta());
                    if (temp) {
                        if (mesiGiornoSp < 72) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        } else {
                            classe.CodErrore = "MISSING_LICENSING"
                        }
                        return temp;
                    }
                }

                //CLASSE VETERANI ALTRE RAZZE
                if ((classe.CodClasse == 'VET')
                    && (this.currentdog.IdRazza != 198)
                    && (this.currentdog.IdRazza != 204)
                ) {
                    let temp = (mesiGiornoSp < 72)
                        || (this.currentdog.mesiFineStagione > 107)
                        || ((codSubKey.indexOf('COURSING') > -1) && !(this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && !this.isLicenzaRacingCorretta());
                    if (temp) {
                        if (mesiGiornoSp < 72) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        } else {
                            classe.CodErrore = "MISSING_LICENSING"
                        }
                        return temp;
                    }
                }
            }

            //SESSIONE MISURAZIONE - RAZZE PLI, WHIPPET
            if ((codSubKey.indexOf('SESSIONE MISURAZIONE') > -1) && (this.currentdog.IdRazza == 198 || this.currentdog.IdRazza == 204)
            ) {
                let temp = this.base.isNullOrEmpty(this.currentdog.Loi)
                    || (mesiGiornoSp < 12)
                    || (this.currentdog.mesiFineStagione > 107) //107: numero magico che mi dice se alla fine dell'anno (ossia della stagione) ha massimo 8 anni e 11 mesi
                    || (this.isMisurazioniLevrieroCorrette(sottoprovaId)); //Mi lascia iscrivere solo se le misurazioni non sono a posto
                if (temp) {
                    if (this.base.isNullOrEmpty(this.currentdog.Loi)) {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 12) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (this.currentdog.mesiFineStagione > 107) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    else {
                        classe.CodErrore = "SIZE_AND_MEASURES_OK";
                    }
                    return temp;
                }
            }

            //SESSIONE MISURAZIONE - ALTRE RAZZE
            if ((codSubKey.indexOf('SESSIONE MISURAZIONE') > -1) && (this.currentdog.IdRazza != 198 && this.currentdog.IdRazza != 204)
            ) {
                let temp = this.base.isNullOrEmpty(this.currentdog.Loi)
                    || (mesiGiornoSp < 12)
                    || (this.currentdog.mesiFineStagione > 107); //107: numero magico che mi dice se alla fine dell'anno (ossia della stagione) ha massimo 8 anni e 11 mesi
                if (temp) {
                    if (this.base.isNullOrEmpty(this.currentdog.Loi)) {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 12) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    return temp;
                }
            }

        }

        //***********************************************************************************
        //************** CONTROLLI LEVRIERI NUOVO REGOLAMENTO (AMNA_%) **********************
        //***********************************************************************************
        // Data inizio nuovo regolamento nella variabile globale LevrieriDataNuovoRegolamento2022
        if (codProtSudd.indexOf('AMNA_') == 0 && nuovoRegolamentoLevrieri) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            // RAZZE PLI, WHIPPET, CIRNECO DELL'ETNA
            let piccolaTaglia = (this.currentdog.IdRazza == 198 || this.currentdog.IdRazza == 204 || this.currentdog.IdRazza == 211);

            //AVVIAMENTO
            if ((codSubKey.indexOf('AVVIAMENTO') > -1)) {

                let minMesi = (piccolaTaglia ? 14 : 17);

                let temp = this.base.isNullOrEmpty(this.currentdog.Loi)
                    || (mesiGiornoSp < minMesi)
                    || this.currentdog.mesiFineStagione > 107 //107: numero magico che mi dice se alla fine dell'anno (ossia della stagione) ha massimi 8 anni e 11 mesi
                    || ((codSubKey.indexOf('COURSING') > -1) && (this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                    || ((codSubKey.indexOf('RACING') > -1) && this.isLicenzaRacingCorretta());
                if (temp) {
                    if (this.base.isNullOrEmpty(this.currentdog.Loi)) {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < minMesi) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (this.currentdog.mesiFineStagione > 107) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    } else {
                        classe.CodErrore = "LICENSING_ALREADY_OK";
                    }
                    return temp;
                }

            }

            //SESSIONE MISURAZIONE - NON PIU' POSSIBILE
            if (codSubKey.indexOf('SESSIONE MISURAZIONE') > -1) {
                let temp = true;
                if (temp) {
                    classe.CodErrore = "SESSIONE_MISURAZIONE_NON_AMMESSA";
                    return temp;
                }
            }

            //COURSING e RACING
            if (codSubKey == 'COURSING' || codSubKey == 'RACING') {

                let minMesi = (piccolaTaglia ? 15 : 18);

                //CLASSE CSS o FCI-OPEN
                if (classe.CodClasse == 'CSS' || classe.CodClasse == 'FCI-OPEN') {
                    let licenzaOK = ((codSubKey.indexOf('COURSING') > -1) && (this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && this.isLicenzaRacingCorretta());
                    let temp = (mesiGiornoSp < minMesi)
                        || (this.currentdog.mesiFineStagione > 107)
                        || !licenzaOK
                        || this.isLicenzaCacil();
                    if (temp) {
                        if (mesiGiornoSp < minMesi) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        else if (!licenzaOK) {
                            classe.CodErrore = "MISSING_LICENSING";
                        }
                        else {
                            classe.CodErrore = "NO_CSS_WITH_LICENSE_CACIL";
                        }
                        return temp;
                    }
                }

                //CLASSE FCI-CACIL
                if (classe.CodClasse == 'FCI-CACIL') {
                    let licenzaOK = ((codSubKey.indexOf('COURSING') > -1) && (this.isLicenzaCoursingCorretta() || this.isLicenzaRacingCorretta()))
                        || ((codSubKey.indexOf('RACING') > -1) && this.isLicenzaRacingCorretta());
                    let temp = (mesiGiornoSp < minMesi)
                        || (this.currentdog.mesiFineStagione > 107)
                        || (this.currentdog.Gruppo == 5)
                        || !licenzaOK
                        || (!this.isLicenzaCacil());
                    if (temp) {
                        if (mesiGiornoSp < minMesi) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (this.currentdog.mesiFineStagione > 107) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        else if (this.currentdog.Gruppo == 5) {
                            classe.CodErrore = "CACIL_NOT_ALLOWED";
                        }
                        else if (!licenzaOK) {
                            classe.CodErrore = "MISSING_LICENSING";
                        }
                        else {
                            classe.CodErrore = "LICENSE_NOT_CACIL";
                        }
                        return temp;
                    }
                }

            }
        }

        //***********************************************************************************
        //************************ CONTROLLI TRACCIA (SUNA_%) *******************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('SUNA_') == 0) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            // Bayerischer e hannover'scher su traccia artificiale
            if (codProtSudd == 'SUNA_BAHA_BHTA') {
                //Giovani: da 12 a 30 mesi
                if (classe.CodClasse == 'GIO') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp < 12
                        || mesiGiornoSp > 30;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp < 12) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (mesiGiornoSp > 30) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        return temp;
                    }
                }
                //Libera: da 24 mesi ai 9 anni compiuti
                else if (classe.CodClasse == 'LIB') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp < 24
                        || mesiGiornoSp > 119;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp < 24) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        else if (mesiGiornoSp > 119) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        return temp;
                    }
                }
            }
            // Bayerischer e hannover'scher su traccia artificiale eccellenza
            else if (codProtSudd == 'SUNA_BAHA_BHTE') {
                //Libera: dai 30 mesi
                if (classe.CodClasse == 'LIB') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp < 30;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp < 30) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        return temp;
                    }
                }
            }
            // Bayerischer e hannover'scher su traccia naturale
            else if (codProtSudd == 'SUNA_BAHA_BHTN') {
                //Libera: dai 30 mesi
                if (classe.CodClasse == 'LIB') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp < 30;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp < 30) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        return temp;
                    }
                }
            }
            // Open Categoria A (Specialistiche) e Open Categoria B (Non specialistiche)
            else if (codProtSudd == 'SUNA_OPEN_OPCA' || codProtSudd == 'SUNA_OPEN_OPCB') {
                //Giovani: fino 30 mesi
                if (classe.CodClasse == 'GIO') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp > 30;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp > 30) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        return temp;
                    }
                }
                //Libera: da 15 mesi in poi e no RSR
                else if (classe.CodClasse == 'LIB') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp < 15;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp < 15) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        return temp;
                    }
                }
            }
            // Brevetto cane limiere e girata
            else if (codProtSudd == 'SUNA_BRLG_RECF' || codProtSudd == 'SUNA_BRLG_RECU' || codProtSudd == 'SUNA_BRLG_TLCF' || codProtSudd == 'SUNA_BRLG_TLCU') {
                // Dai 12 mesi
                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < 12;
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 12) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    return temp;
                }
            }
            // Alpenlaendische Dachsbracke - cane idoneo lavoro singolo su cinghiale
            else if (codProtSudd == 'SUNA_DAS_CISC') {
                // Dai 12 mesi
                let temp = mesiGiornoSp < 12;
                if (temp) {
                    if (mesiGiornoSp < 12) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    return temp;
                }
            }
            // Bayerischer e hannover'scher Sant Ubertus
            else if (codProtSudd == 'SUNA_BAHA_BHHU') {
                //Libera: dai 30 mesi
                if (classe.CodClasse == 'LIB') {
                    let temp = (this.currentdog.TipoRegistrazione == 'R1')
                        || mesiGiornoSp < 30;
                    if (temp) {
                        if (this.currentdog.TipoRegistrazione == 'R1') {
                            classe.CodErrore = "RSR_NOT_ALLOWED";
                        }
                        else if (mesiGiornoSp < 30) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        return temp;
                    }
                }
            }
            // Bassotti
            else if (codProtSudd == 'SUNA_BASS_BC' || codProtSudd == 'SUNA_BASS_BCO' || codProtSudd == 'SUNA_BASS_BCJ'
                || codProtSudd == 'SUNA_BASS_CRCO' || codProtSudd == 'SUNA_BASS_CRCOO' || codProtSudd == 'SUNA_BASS_CRCOJ'
                || codProtSudd == 'SUNA_BASS_BPS' || codProtSudd == 'SUNA_BASS_TSVL' || codProtSudd == 'SUNA_BASS_TSW4'
                || codProtSudd == 'SUNA_BASS_TSWH' || codProtSudd == 'SUNA_BASS_TSWHO' || codProtSudd == 'SUNA_BASS_TSWHJ'
                || codProtSudd == 'SUNA_BASS_TSPO' || codProtSudd == 'SUNA_BASS_TSPOO' || codProtSudd == 'SUNA_BASS_TSPOJ'
                || codProtSudd == 'SUNA_BASS_CPWA' || codProtSudd == 'SUNA_BASS_CPWAO' || codProtSudd == 'SUNA_BASS_CPWAJ'
                || codProtSudd == 'SUNA_BASS_BWAT' || codProtSudd == 'SUNA_BASS_BTSN' || codProtSudd == 'SUNA_BASS_BCBN'
                || codProtSudd == 'SUNA_BASS_CEBO' || codProtSudd == 'SUNA_BASS_CEBOO' || codProtSudd == 'SUNA_BASS_CEBOJ'
                || codProtSudd == 'SUNA_BASS_VP' || codProtSudd == 'SUNA_BASS_VJP' || codProtSudd == 'SUNA_BASS_VGP'
                || codProtSudd == 'SUNA_BASS_TSP4') {
                // Imposto et� minima per requisito partecipazione (default 9)
                let mesiMin = 9;
                if (codProtSudd == 'SUNA_BASS_BC' || codProtSudd == 'SUNA_BASS_BCO' || codProtSudd == 'SUNA_BASS_BCJ' || codProtSudd == 'SUNA_BASS_BTSN') {
                    mesiMin = 12;
                } else if (codProtSudd == 'SUNA_BASS_VP' || codProtSudd == 'SUNA_BASS_VJP' || codProtSudd == 'SUNA_BASS_VGP') {
                    mesiMin = 15;
                }
                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < mesiMin;
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < mesiMin) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    return temp;
                }
                //Giovani: da 9 a 30 mesi
                if (classe.CodClasse == 'GIO') {
                    let temp = mesiGiornoSp > 30;
                    if (temp) {
                        if (mesiGiornoSp > 30) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        return temp;
                    }
                }
                //Libera: da 9 mesi in poi
                else if (classe.CodClasse == 'LIB') {
                    let temp = mesiGiornoSp < 9;
                    if (temp) {
                        if (mesiGiornoSp < 9) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        return temp;
                    }
                }
            }
            // Terrier
            else if (codProtSudd == 'SUNA_TERR_TAN' || codProtSudd == 'SUNA_TERR_TC' || codProtSudd == 'SUNA_TERR_TCL' || codProtSudd == 'SUNA_TERR_TPS'
                || codProtSudd == 'SUNA_TERR_TSP' || codProtSudd == 'SUNA_TERR_TTA4' || codProtSudd == 'SUNA_TERR_TTAS' || codProtSudd == 'SUNA_TERR_TTSU'
                || codProtSudd == 'SUNA_TERR_TWAT' || codProtSudd == 'SUNA_TERR_TPM') {
                // Imposto et� minima per requisito partecipazione (default 9)
                let mesiMin = 9;
                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < mesiMin;
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < mesiMin) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    return temp;
                }
                //Giovani: da 9 a 30 mesi
                if (classe.CodClasse == 'GIO') {
                    let temp = mesiGiornoSp > 30;
                    if (temp) {
                        if (mesiGiornoSp > 30) {
                            classe.CodErrore = "DOG_TOO_OLD";
                        }
                        return temp;
                    }
                }
                //Libera: da 9 mesi in poi
                else if (classe.CodClasse == 'LIB') {
                    let temp = mesiGiornoSp < 9;
                    if (temp) {
                        if (mesiGiornoSp < 9) {
                            classe.CodErrore = "DOG_TOO_YOUNG";
                        }
                        return temp;
                    }
                }
            }
            // Tutte le altre: min 12 mesi e per GIO max 30 mesi + no RSR
            else {
                let temp = mesiGiornoSp < 12
                    || (classe.CodClasse == 'GIO' && mesiGiornoSp > 30)
                    || (this.currentdog.TipoRegistrazione == 'R1');
                if (temp) {
                    if (mesiGiornoSp < 12) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (classe.CodClasse == 'GIO' && mesiGiornoSp > 30) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    else if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    return temp;
                }
            }
        }

        //***********************************************************************************
        //*************** CONTROLLI CERCA TARTUFO / LAGOTTI (LRNA_%) ************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('LRNA_') == 0) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            //Esordienti da 4 a 12 mesi
            if (classe.CodClasse == 'ESO') {

                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < 4
                    || mesiGiornoSp > 12;
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 4) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (mesiGiornoSp > 12) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    return temp;
                }
            }
            //Giovani: da 9 a 30 mesi
            else if (classe.CodClasse == 'GIO') {
                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < 9
                    || mesiGiornoSp > 30;
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 9) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (mesiGiornoSp > 30) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    return temp;
                }
            }
            //Libera: oltre i 15 mesi
            else if (classe.CodClasse == 'LIB') {
                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < 15;
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < 15) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    return temp;
                }
            }
            //Veterani: oltre i 7 anni
            else if (classe.CodClasse == 'VET') {
                let temp = (this.currentdog.TipoRegistrazione == 'R1')
                    || mesiGiornoSp < (7 * 12);
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    else if (mesiGiornoSp < (7 * 12)) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    return temp;
                }
            }
            //RSR: solo soggetti RSR
            else if (classe.CodClasse == 'RSR') {
                let temp = (this.currentdog.TipoRegistrazione != 'R1');
                if (temp) {
                    if (this.currentdog.TipoRegistrazione != 'R1') {
                        classe.CodErrore = "RSR_ONLY_ALLOWED";
                    }
                    return temp;
                }
            }

            if (codSubKey.indexOf('TIPO B') > -1) {
                let temp = (!this.currentlag || this.base.isNullOrEmpty(this.currentlag.CartellinoProva) || (this.currentlag.CartellinoProva.toUpperCase().indexOf("CAC") == -1));
                if (temp) {
                    classe.CodErrore = "MISSING_REQUIREMENT_LRNA";
                    return temp;
                }
            }
        }

        //***********************************************************************************
        //*********************************** CAE-1 (CAE1_%) ********************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('CAE1_') == 0) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            //Minimo 15 mesi
            //Ammesse tutte le razze (gruppi 1-11) ed anche i meticci (gruppo 99)
            let temp = (mesiGiornoSp < 15);
            if (temp) {
                if (mesiGiornoSp < 15) {
                    classe.CodErrore = "DOG_TOO_YOUNG";
                }
                return temp;
            }

        }

        //***********************************************************************************
        //******************************** MONDIORING (MDRG_%) ******************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('MDRG_') == 0) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            //Classe unica: minimo 12 mesi
            //Ammesse tutte le razze (gruppi 1-11)
            //NO RSR
            let temp = (this.currentdog.TipoRegistrazione == 'R1')
                || (mesiGiornoSp < 12);
            if (temp) {
                if (this.currentdog.TipoRegistrazione == 'R1') {
                    classe.CodErrore = "RSR_NOT_ALLOWED";
                }
                else if (mesiGiornoSp < 12) {
                    classe.CodErrore = "DOG_TOO_YOUNG";
                }
                return temp;
            }
        }

        //***********************************************************************************
        //********************************** SEGUITA (SGNA_%) *******************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('SGNA_') == 0) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui
            let err = this.checkErrSGNA(dataGiorno, codSubKey, this.currentdog);
            if (err != null) {
                classe.CodErrore = err;
                return true;
            }

        //    //Massimo 10 anni
        //    // 14.01.2024 LA: per il brevetto di muta il limite dei 10 anni non c'� pi� (mail di Silvia Tortora del 13.01.2024)
        //    let temp = (mesiGiornoSp < 12)
        //        || (mesiGiornoSp > 12 * 10 && codSubKey.indexOf('BREVETTO DI MUTA') == -1)
        //        || ((this.currentdog.TipoRegistrazione == 'R1') && ((codSubKey.indexOf('COPPIE') > -1) || (codSubKey.indexOf('MUTE') > -1) || (codSubKey.indexOf('BREVETTO DI MUTA') > -1)));
        //    if (temp) {
        //        if (mesiGiornoSp < 12) {
        //            classe.CodErrore = "DOG_TOO_YOUNG";
        //        }
        //        else if (mesiGiornoSp > 12 * 10 && codSubKey.indexOf('BREVETTO DI MUTA') == -1) {
        //            classe.CodErrore = "DOG_TOO_OLD";
        //        } else {
        //            classe.CodErrore = "RSR_NOT_ALLOWED";
        //        }
        //        return temp;
        //    }
        }

        //***********************************************************************************
        //********************************** DERBY ******************************************
        //***********************************************************************************
        else if (this.eventoDto.dto.GestManTipiProve && this.eventoDto.dto.GestManTipiProve.indexOf('DERBY') >= 0) {

            // I controlli sull'et� vengono fatti sotto in quanto standard
            controlliMesiDaAEffettuati = false;

            let temp = (this.currentdog.IdNazioneAllevatore != 45); //ALLEVATORE ITALIANO
            if (temp) {
                classe.CodErrore = "NOT_ITALIAN_BREEDER";
                return temp;
            }
        }

        //***********************************************************************************
        //********************************** RETRIEVER **************************************
        //***********************************************************************************
        else if ((codProtSudd.indexOf('RTNA_') == 0) && (codProtSudd != "RTNA_CHA_")) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            // Almeno 18 mesi
            let temp = (mesiGiornoSp < 18);
            if (temp) {
                if (mesiGiornoSp < 18) {
                    classe.CodErrore = "DOG_TOO_YOUNG";
                }
                return temp;
            }
        }

        //***********************************************************************************
        //*************************** MONITORAGGIO BECCACCIA ********************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('SENA_') == 0 && codSubKey.indexOf('BREVETTO MONITORAGGIO BECCACCIA') > -1) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            // Almeno 24 mesi
            let temp = (mesiGiornoSp < 24);
            if (temp) {
                if (mesiGiornoSp < 24) {
                    classe.CodErrore = "DOG_TOO_YOUNG";
                }
                return temp;
            }
        }

        //***********************************************************************************
        //************************** CONTROLLI TANA (TANA_%) ********************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('TANA_') == 0) {

            controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

            if (
                // Bassotti
                codProtSudd == 'TANA_BASS_BHFK' || codProtSudd == 'TANA_BASS_BHFKO' || codProtSudd == 'TANA_BASS_BHFKJ'
                || codProtSudd == 'TANA_BASS_KBJA' || codProtSudd == 'TANA_BASS_KBJAO' || codProtSudd == 'TANA_BASS_KBJAJ'
                || codProtSudd == 'TANA_BASS_BHFKS' || codProtSudd == 'TANA_BASS_BHFKSO' || codProtSudd == 'TANA_BASS_BHFKSJ'
                || codProtSudd == 'TANA_BASS_BPS'
                // Terrier
                || codProtSudd == 'TANA_TERR_BHFK' || codProtSudd == 'TANA_TERR_BHFKS'
            ) {
                let mesiMin = 9; // Bassotti
                if (codProtSudd == 'TANA_TERR_BHFK' || codProtSudd == 'TANA_TERR_BHFKS') {
                    mesiMin = 12; // Terrier
                }
                let temp = (mesiGiornoSp < mesiMin)
                    || (classe.CodClasse == 'GIO' && mesiGiornoSp > 30)
                    || (this.currentdog.TipoRegistrazione == 'R1');
                if (temp) {
                    if (mesiGiornoSp < mesiMin) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (classe.CodClasse == 'GIO' && mesiGiornoSp > 30) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    else if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    return temp;
                }
            }
            // Tutte le altre: no RSR (et� fatta sotto nello standard)
            else {

                // I controlli sull'et� vengono fatti sotto in quanto standard
                controlliMesiDaAEffettuati = false;

                let temp = (this.currentdog.TipoRegistrazione == 'R1');
                if (temp) {
                    if (this.currentdog.TipoRegistrazione == 'R1') {
                        classe.CodErrore = "RSR_NOT_ALLOWED";
                    }
                    return temp;
                }
            }
        }

        //***********************************************************************************
        //******************** CONTROLLI UTILITA' E DIFESA (UTNA_%) *************************
        //***********************************************************************************
        else if (codProtSudd.indexOf('UTNA_') == 0) {

            if (classe.MesiDaSp && classe.MesiASp) {

                controlliMesiDaAEffettuati = true; // tutti i controlli mesi da/a fatti qui

                // UTNA:la gestione dell'et� � basata sulle apposite colonne MesiDa e MesiA
                // presenti in C4 per PrvSpConfig/Classe
                let mesiMin = classe.MesiDaSp;
                let mesiMax = classe.MesiASp;
                let temp = (mesiGiornoSp < mesiMin)
                    || (mesiGiornoSp > mesiMax);
                if (temp) {
                    if (mesiGiornoSp < mesiMin) {
                        classe.CodErrore = "DOG_TOO_YOUNG";
                    }
                    else if (mesiGiornoSp > mesiMax) {
                        classe.CodErrore = "DOG_TOO_OLD";
                    }
                    return temp;
                }

            }
        }

        //***********************************************************************************
        //*************************** CONTROLLO STANDARD MESI *******************************
        //***********************************************************************************
        // LA 07.05.2024
        // Con mail di Luca Mollo del 09.05.2024 verifichiamo, se non gi� fatti per altri
        // controlli specifici precedenti: "Ogni prova pu� essere divisa in diverse singole prove.
        // Cos�, a seconda dell'et� dei cani ammessi a parteciparvi, non inferiore ai 12 mesi salvo
        // differenti previsioni indicate nei regolamenti delle singole discipline;
        // possono svolgersi prove per giovani quando l'et� dei concorrenti non �:
        // n� inferiore a 12 mesi n� superiore a 30 mesi, per anziani di et� superiore ai 6 anni,
        // oppure Libere quando nessun limite di et� � prescritto (fermo restando l�et� minima dei 12 mesi).
        if (!controlliMesiDaAEffettuati) {
            let temp = (mesiGiornoSp < 12)
                || (classe.CodClasse == 'GIO' && mesiGiornoSp > 30);
            if (temp) {
                if (mesiGiornoSp < 12) {
                    classe.CodErrore = "DOG_TOO_YOUNG";
                }
                else if (classe.CodClasse == 'GIO' && mesiGiornoSp > 30) {
                    classe.CodErrore = "DOG_TOO_OLD";
                }
                return temp;
            }
        }

        //***********************************************************************************
        //********************************** CONTROLLI GLOBALI ******************************
        //***********************************************************************************
        //GF SETT 2020: Et� massima se DataNascitaMinima � valorizzato a livello di sottoprova
        if (dataNascitaMinima) {
            let err = this.checkErrDataNasciatMinimaSp(dataNascitaMinima, this.currentdog);
            if (err != null) {
                classe.CodErrore = err;
                return true;
            }
        }

        return false;
    }

    public noClassesAllowed(classes, sp): boolean {
        if (classes.some(c => !this.isClassExcluded(c, sp.DataGiorno, sp.CodProtSudd, sp.CodSubKey, sp.Id, sp.DataNascitaMinima, sp.NuovoRegolamentoLevrieri))) {
            return false;
        }
        return true;
    }

    public isRaggClassExcluded(classe, dataGiorno, codSubKey, dataNascitaMinima): boolean {

        // Entra qui solo per i raggruppamenti della seguita
        classe.CodErrore = null;

        //***********************************************************************************
        //********************************** SEGUITA (SGNA_%) *******************************
        //***********************************************************************************

        for (let dog of this.currentragg.Soggetti) {
            let err = this.checkErrSGNA(dataGiorno, codSubKey, dog);
            if (err != null) {
                classe.CodErrore = err;
                return true;
            }
        }

        //***********************************************************************************
        //********************************** CONTROLLI GLOBALI ******************************
        //***********************************************************************************
        //GF SETT 2020: Et� massima se DataNascitaMinima � valorizzato a livello di sottoprova
        if (dataNascitaMinima) {
            for (let dog of this.currentragg.Soggetti) {
                let err = this.checkErrDataNasciatMinimaSp(dataNascitaMinima, dog);
                if (err != null) {
                    classe.CodErrore = err;
                    return true;
                }
            }
        }

        return false;
    }

    public noRaggClassesAllowed(classes, sp): boolean {
        if (classes.some(c => !this.isRaggClassExcluded(c, sp.DataGiorno, sp.CodSubKey, sp.DataNascitaMinima))) {
            return false;
        }
        return true;
    }

    private checkErrSGNA(dataGiorno, codSubKey, dog): string | null {
        //Massimo 10 anni
        // 14.01.2024 LA: per il brevetto di muta il limite dei 10 anni non c'e' piu' (mail di Silvia Tortora del 13.01.2024)
        var mesiGiornoSp = this.getMesiGiorno(this.base.sanitizeSingleDate(dog.DataNascita).split('-'), this.base.sanitizeSingleDate(dataGiorno).split('-'));

        let temp = (mesiGiornoSp < 12)
            || (mesiGiornoSp > 12 * 10 && codSubKey.indexOf('BREVETTO DI MUTA') == -1)
            || ((dog.TipoRegistrazione == 'R1') && ((codSubKey.indexOf('COPPIE') > -1) || (codSubKey.indexOf('MUTE') > -1) || (codSubKey.indexOf('BREVETTO DI MUTA') > -1)));

        if (temp) {
            if (mesiGiornoSp < 12) {
                return "DOG_TOO_YOUNG";
            } else if (mesiGiornoSp > 12 * 10 && codSubKey.indexOf('BREVETTO DI MUTA') == -1) {
                return "DOG_TOO_OLD";
            } else {
                return "RSR_NOT_ALLOWED";
            }
        }

        return null;
    }

    private checkErrDataNasciatMinimaSp(dataNascitaMinima, dog): string | null {

        if (dataNascitaMinima) {
            let temp = (dataNascitaMinima > dog.DataNascita);
            if (temp) {
                return "DOG_TOO_OLD_DT_MIN";
            }
        }

        return null;

    }

    private isMisurazioniLevrieroCorrette(sottoprovaId): boolean {
        // Misurazioni corrette anche se c'� la taglia confermata
        if (this.currentdog.TagliaXProve) {
            return true;
        }
        if (this.currentlev != null) {
            //le misurazioni del levriero sono corrette se ci sono entrambe
            if (!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione1) && !this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione2)
                && this.currentlev.DataMisurazione1 != null && this.currentlev.DataMisurazione2 != null) {
                return true;
            }
            //oppure se ce n'� una sola, ma fatta dopo il compimento dei due anni d'et�
            var datatemp = new Date(this.base.sanitizeSingleDate(this.currentdog.DataNascita).split("-"));
            datatemp.setFullYear(datatemp.getFullYear() + 2);
            if (!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione1) && (this.currentlev.DataMisurazione1 != null)
                && (datatemp <= new Date(this.base.sanitizeSingleDate(this.currentlev.DataMisurazione1).split("-")))) {
                return true;
            }
            if (!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione2) && this.currentlev.DataMisurazione2 != null
                && (datatemp <= new Date(this.base.sanitizeSingleDate(this.currentlev.DataMisurazione2).split("-")))) {
                return true;
            }
            //GF luglio 2020 Da richiesta del ticket CAS-36930-P8M9Y0: solo per COURSING MOCOGNO del 23 agosto 2020 basta che abbia almeno 1 misurazione
            if (sottoprovaId == 2477) {
                if (!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione1) && (this.currentlev.DataMisurazione1 != null)) {
                    return true;
                }
                if (!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione2) && (this.currentlev.DataMisurazione2 != null)) {
                    return true;
                }
            }
        }
        return false;
    }

    //GF luglio 2020 Da richiesta del ticket CAS-36930-P8M9Y0
    //private isMisurazioniLevrieroCorretteLASCO(): boolean {
    //    //le misurazioni del levriero sono corrette se ce n'� almeno una
    //    if (this.currentlev != null) {
    //        if ((!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione1) && this.currentlev.DataMisurazione1 != null) ||
    //             (!this.base.isNullOrEmpty(this.currentlev.EsitoMisurazione2) && this.currentlev.DataMisurazione2 != null)) {
    //            return true;
    //        }
    //    }
    //    return false;
    //}

    private isLicenzaCoursingCorretta(): boolean {
        if (this.currentlev != null) {
            if (!this.base.isNullOrEmpty(this.currentlev.LicenzaCoursing) && !this.currentlev.LicenzaSospesaCoursing) {
                return true;
            }
        }
        return false;
    }

    private isLicenzaRacingCorretta(): boolean {
        if (this.currentlev != null) {
            if (!this.base.isNullOrEmpty(this.currentlev.LicenzaRacing) && !this.currentlev.LicenzaSospesaRacing) {
                return true;
            }
        }
        return false;
    }

    private isLicenzaCacil(): boolean {
        if (this.currentlev != null) {
            if (!this.base.isNullOrEmpty(this.currentlev.LicenzaCoursing) || !this.base.isNullOrEmpty(this.currentlev.LicenzaRacing)) {
                if (this.nuovaLicenzaCacilLevrieri) {
                    // Dal 2023 per essere valida per la classe FCI-CACIL deve avere il flag LicenzaCacil2023
                    return this.currentlev.LicenzaCacil2023;
                } else {
                    return this.currentlev.LicenzaCacil;
                }
            }
        }
        return false;
    }

    public getMesiGiorno(dataNascitaCane, giorno) {
        var months = (giorno[0] - dataNascitaCane[0]) * 12;
        months -= parseInt(dataNascitaCane[1]);
        months += parseInt(giorno[1]);
        if (dataNascitaCane[2] > giorno[2]) months--;
        return months;
    }

    public canConductDogs(owner) {
        return owner.IsIscrittoAlboAddestratori
            || owner.IsConduttoreCoppieMute
            || owner.IsConduttore
            || owner.IsConduttoreCae;
    }

    // GOOGLE MAPS
    // info componente su: https://angular-maps.com/api-docs/agm-core/
    // OLD info componente su: https://blog.angularindepth.com/google-maps-is-now-an-angular-component-821ec61d2a0
    //     @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

    setCenterCoordinates(coordinate: any) {
        if (coordinate.hasOwnProperty('length')) {
            this.lat = Number(coordinate.split('|')[0]);
            this.lng = Number(coordinate.split('|')[1]);
        }
    }

    public showModalInfo(evento: any) {
        this.modalService.openModalInfo(evento);
    }

    public iscrizioneVietataPerSospensione(dog, sp) {
        if (dog) {
            return dog.spVietatePerSospensione.find(w => w == sp.Id);
        }
        return false;
    }

    public noIscrittiEV(ev) {
        return ev.NumSoggettiIscrittiGlobale + ev.PostiPrenotati;
    }

    public noIscrittiSP(sp, ev) {
        let ret = ((sp.CodSubKey == 'COPPIE' || sp.CodSubKey == 'MUTE' || sp.CodSubKey == 'BREVETTO DI MUTA') ? sp.NumCoppieMuteIscritte : sp.NumSoggettiIscritti) + ev.PostiPrenotati;
        return ret;
    }

    public postiDisponibili(ev, sp) {
        let ret = !sp.IsIscrizioneScaduta;
        ret = ret && (sp.CaeOk || sp.SeguitaOk);
        ret = ret && (ev.TipoLimitazionePosti == 'NN'
            || ev.TipoLimitazionePosti == 'CU'
            || (ev.TipoLimitazionePosti == 'EV' && ev.MaxSoggettiIscrittiGlobale == 0)
            || (ev.TipoLimitazionePosti == 'EV' && ev.MaxSoggettiIscrittiGlobale > 0 && this.noIscrittiEV(ev) < ev.MaxSoggettiIscrittiGlobale + this.postiPrenotatiUtente)
            || (ev.TipoLimitazionePosti == 'SP' && sp.MaxSoggettiIscritti == 0)
            || (ev.TipoLimitazionePosti == 'SP' && sp.MaxSoggettiIscritti > 0 && this.noIscrittiSP(sp, ev) < sp.MaxSoggettiIscritti + this.postiPrenotatiUtente)
        );
        return ret;
    }

    public postiDisponibiliCoppieMute(ev, sp) {
        let ret = !sp.IsIscrizioneScaduta;
        ret = ret && (ev.TipoLimitazionePosti == 'NN'
            || ev.TipoLimitazionePosti == 'CU'
            || (ev.TipoLimitazionePosti == 'EV' && ev.MaxSoggettiIscrittiGlobale == 0)
            || (ev.TipoLimitazionePosti == 'EV' && ev.MaxSoggettiIscrittiGlobale > 0 && this.noIscrittiEV(ev) < ev.MaxSoggettiIscrittiGlobale + this.postiPrenotatiUtente)
            || (ev.TipoLimitazionePosti == 'SP' && sp.MaxSoggettiIscritti == 0)
            || (ev.TipoLimitazionePosti == 'SP' && sp.MaxSoggettiIscritti > 0 && this.noIscrittiSP(sp, ev) < sp.MaxSoggettiIscritti + this.postiPrenotatiUtente)
        );
        return ret;
    }

    public isCheckIscrizioneVisible(ev, sp, cl) {
        let ret = ev.TipoLimitazionePosti == 'NN';
        ret = ret || (ev.TipoLimitazionePosti == 'CU' && !this.classeNonSelPerLimitazioni(cl, ev.Limitazioni));
        ret = ret || (ev.TipoLimitazionePosti == 'EV' && ev.MaxSoggettiIscrittiGlobale == 0);
        ret = ret || (ev.TipoLimitazionePosti == 'EV' && ev.MaxSoggettiIscrittiGlobale > 0 && this.noIscrittiEV(ev) < ev.MaxSoggettiIscrittiGlobale + this.postiPrenotatiUtente);
        ret = ret || (ev.TipoLimitazionePosti == 'SP' && sp.MaxSoggettiIscritti == 0);
        ret = ret || (ev.TipoLimitazionePosti == 'SP' && sp.MaxSoggettiIscritti > 0 && this.noIscrittiSP(sp, ev) < sp.MaxSoggettiIscritti + this.postiPrenotatiUtente);
        return ret;
    }

    private allSoggettiPresenti(soggetti, pagamenti): boolean {

        for (let i = 0; i < soggetti.length; i++) {
            let c = soggetti[i];
            if (!pagamenti.some(u => u.Chip == c.Chip)) {
                return false;
            }
        }
        return true;
    }

    public selectedRagg(e, owner) {
        this.apiService.post("Payments/GetRaggStatus", { Ragg: e, EventId: this.eventoDto.dto.Id }).subscribe(currentRaggStatus => {
            this.currentragg = e;
            this.currentdog = null;
            this.currentlev = null;
            this.currentlag = null;
            this.eventoDto.dto.SottoProve.forEach(sp => {

                //pulisco le variabili dall'eventuale giro precedente
                sp.Pagato = false;
                sp.PagatoDaAltri = false;
                let temp = new Date(this.base.sanitizeSingleDate(sp.DataScadenzaIscrizioni));
                let oggi = new Date(this.base.getToday());
                sp.IsIscrizioneScaduta = temp < oggi;
                sp.CaeOk = true;
                sp.SeguitaOk = true;

                //analizzo i pagamenti della sottoprova in canna: ho tutti i pagamenti dei soggetti del raggruppamento
                if (currentRaggStatus.dto.Payments.some(p => p.GpSottoProveId == sp.Id)) {
                    let soggettiSpPagati = currentRaggStatus.dto.Payments.filter(p => p.GpSottoProveId == sp.Id);
                    //Se tutti i soggetti sono gi� pagati da me, lo metto pagato
                    let soggettiSpPagatiDaMe = soggettiSpPagati.filter(p => p.IdUser == owner.IdUser);
                    //Se tutti i soggetti sono stati pagati da altri, metto pagato da altri
                    let soggettiSpPagatiDaAltri = soggettiSpPagati.filter(p => p.IdUser != owner.IdUser);

                    if (this.allSoggettiPresenti(e.Soggetti, soggettiSpPagatiDaMe)) {
                        sp.Pagato = true;
                    } else if (this.allSoggettiPresenti(e.Soggetti, soggettiSpPagatiDaAltri)) {
                        sp.PagatoDaAltri = true;
                    }
                    else {
                        //Entrambi i flag a true: nuovo caso warning gestito in pagina
                        sp.Pagato = true;
                        sp.PagatoDaAltri = true;
                        sp.SoggettiSpPagati = soggettiSpPagati;
                    }

                    //In tutti gli altri casi, metto un errore
                    if (currentRaggStatus.dto.Payments.find(p => p.GpSottoProveId == sp.Id && p.IdUser == owner.IdUser)) {
                        sp.Pagato = true;
                    }
                    else {
                        sp.PagatoDaAltri = true;
                    }
                } else {
                    sp.GpClassi.forEach(cl => {
                        cl.IsSelected = false;
                        cl.IsSelectedAltro = false;
                        currentRaggStatus.dto.Enrollments.forEach(enrol => {
                            for (let i = 0; i < e.Soggetti.length; i++) {
                                let c = e.Soggetti[i];
                                // Stesso raggruppamento gi� associato
                                if (enrol.GpClassiId == cl.Id
                                    && enrol.GpSottoProveId == sp.Id
                                    && enrol.IdCane == c.CaniId
                                    && enrol.IdUser == owner.IdUser
                                    && (enrol.RaggId == 0 || enrol.RaggId == e.Id)
                                    && !cl.IsSelected) {
                                    cl.IsSelected = true;
                                    //break;
                                }
                                // Stesso cane gi� associato in altro raggruppamento
                                if (enrol.GpClassiId == cl.Id
                                    && enrol.GpSottoProveId == sp.Id
                                    && enrol.IdCane == c.CaniId
                                    && enrol.IdUser == owner.IdUser
                                    && enrol.RaggId != 0 
                                    && enrol.RaggId != e.Id
                                    && !cl.IsSelectedAltro) {
                                    cl.IsSelectedAltro = true;
                                }
                            }
                        });

                    });
                }
            });

            this.eventoDto.dto.SottoProve.forEach(sp => {
                this.spOthersAlreadyChecked(sp);
            });

            this.enrollmentModified = false;
            this.modalSelectDogRef.hide();
        });
    }

    public checkTipoSpRagg(codSubKey) {
        switch (this.currentragg.Tipo) {
            case "C": if (codSubKey == "COPPIE") return true;
            else return false;
            case "M": if (codSubKey == "MUTE" || codSubKey == "BREVETTO DI MUTA") return true;
            else return false;
            default: return false;
        }
    }

    public hoCoppieMute(list) {
        return list.some(s => { return this.checkTipoSpRagg(s.CodSubKey); });
    }

    public spWarningRagg: any;
    public showModalWarningRaggInfo(t, sp) {
        this.spWarningRagg = sp;
        this.modalWarningRaggInfoRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true, class: 'modal-lg' });
    }

    public hideModalWarningRaggInfo() {
        this.spWarningRagg = null;
        this.modalWarningRaggInfoRef.hide();
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public openPopRequisiti(t: TemplateRef<any>, sp) {
        this.sottoprovaSenzaRequisiti = sp;
        this.modalRequisitiRef = this.modalServiceBs.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public IsRequisitiOk(sottopr) {

        //GF FEB 2025 tolti controlli se uno vuole fare il bhvt nella stessa mani per alcune sottoprove
        if ((sottopr.CodProtSudd == 'UTNA_5IGP1_') || (sottopr.CodProtSudd == 'UTNA_IGP1_') || (sottopr.CodProtSudd == 'UTNA_5IFH1_') || (sottopr.CodProtSudd == 'UTNA_IFH1_')) {
            if (this.eventoDto.dto.SottoProve.some(sp => ((sp.CodProtSudd == 'UTNA_BHVA_') || (sp.CodProtSudd == 'UTNA_5BHVA_') || (sp.CodProtSudd == 'UTNA_BHVT_') || (sp.CodProtSudd == 'UTNA_5BHVT_')) && (sp.Pagato || sp.PagatoDaAltri || (sp.GpClassi.some(c => c.IsSelected))))) {
                return true;
            }
        }
        
        if (sottopr.NumRequisitiDaSoddisfare == 0) {
            return true;
        }
        if (this.currentdog.RequisitiCane) {
            return this.currentdog.RequisitiCane.some(z => {
                return z.PrvSpConfigSbloccate.some(h => h.Id == sottopr.PrvSpConfigId);
            });
        }
        return false;
    }
}
