import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import * as Sentry from "@sentry/angular";
import { AuthGuard } from './auth/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { routes } from './app.routing';
import { environment } from '../environments/environment';
import { translocoLoader } from './transloco.loader';
import { TranslocoModule, TRANSLOCO_CONFIG, TranslocoConfig, TranslocoService } from '@ngneat/transloco';
import { ApiService } from './app.service';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { HomeComponent } from './home/home.component';
import { EnrollmentsComponent } from './enrollments/enrollments.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ResultsComponent } from './results/results.component';
import { InformationsComponent } from './informations/informations.component';
import { WarningsComponent } from './warnings/warnings.component';
import { ENCI_LogoComponent } from './ENCI_globals/ENCI_Logo/ENCI_Logo.component';
import { FCI_LogoComponent } from './ENCI_globals/FCI_Logo/FCI_Logo.component';
import { ErrorComponent } from './error/error.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ENCI_WarningsComponent } from './ENCI_globals/ENCI_Warnings/ENCI_Warnings.component';
import { SubtrialsComponent } from './subtrials/subtrials.component';
import { ENCI_CalendarSectionComponent } from './ENCI_globals/ENCI_CalendarSection/ENCI_CalendarSection.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover'
import { ConfEmailOkComponent } from './confemailok/confemailok.component';
import { ConfEmailErrorComponent } from './confemailerror/confemailerror.component';
import { OwnerComponent } from './owner/owner.component';
import { MyDogsComponent } from './mydogs/mydogs.component';
import { DogListItemComponent } from './Shared/doglistitem/doglistitem.component';
import { EditDogComponent } from './Shared/editdog/editdog.component';
import { RazzaFilterPipe } from './Shared/razzafilterpipe';
import { LocaleDatePipe } from './Shared/localedate.pipe';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { InscriptionsComponent } from './inscriptions/inscriptions.component';
import { InscriptionListItemComponent } from './Shared/inscriptionlistitem/inscriptionlistitem.component';
import { GroupFilterPipe } from './Shared/groupfilterpipe';
//import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';
import { ColumnFilterPipe } from './Shared/columnfilterpipe';
import { DataGiornoFilterPipe } from './Shared/datagiornofilterpipe';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ImageUploadWithPreviewComponent } from './Shared/uploadwithpreview/uploadwithpreview.component';
import { PasswordOkComponent } from './passwordok/passwordok.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventDetailsLightComponent } from './Shared/eventdetailslight/eventdetailslight.component';
import { EventDetailsModalsComponent } from './Shared/eventdetailsmodals/eventdetailsmodals.component';
import { ModalService } from './app.modalservice';
import { EventDetailsResultComponent } from './Shared/eventdetailsresult/eventdetailsresult.component';
import { CountDownComponent } from './Shared/count-down/count-down.component';
import { EditRaggComponent } from './Shared/editragg/editragg.component';
import { SingleRaggComponent } from './Shared/singleragg/singleragg.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { RazzaCodEnciFilterPipe } from './Shared/razzacodencifilterpipe';
import { RequisitiComponent } from './Shared/requisiti/requisiti.component';
import { RequisitiItemComponent } from './Shared/requisitiitem/requisitiitem.component';
import { FilterPipe } from './Shared/filterpipe';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CallbackFromAuthComponent } from './callback-from-auth/callback-from-auth.component';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');
export function preloadLanguages(apiService: ApiService, transloco: TranslocoService) {
    return () => {
        return apiService.get("/Utils/GetGpLanguages", null).toPromise().then(result => {
            const traslocoLanguages = (<any[]>result.dto.allLanguages).map(t => ({ id: t.LanguageCulture, label: t.Name }));
            const defaultLanguage = <string>result.dto.defaultLanguage;
            transloco.setAvailableLangs(traslocoLanguages);
            transloco.setDefaultLang(defaultLanguage);
            const activeLang = localStorage.getItem("activeLang");
            transloco.setActiveLang(activeLang ? activeLang : defaultLanguage);
            return transloco.load(defaultLanguage).toPromise();
        });
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        HomeComponent,
        EnrollmentsComponent,
        StatisticsComponent,
        CatalogsComponent,
        ResultsComponent,
        InformationsComponent,
        WarningsComponent,
        ENCI_LogoComponent,
        FCI_LogoComponent,
        NavMenuComponent,
        ErrorComponent,
        SubtrialsComponent,
        ENCI_WarningsComponent,
        ENCI_CalendarSectionComponent,
        ConfEmailOkComponent,
        ConfEmailErrorComponent,
        OwnerComponent,
        MyDogsComponent,
        DogListItemComponent,
        EditRaggComponent,
        SingleRaggComponent,
        UnauthorizedComponent,
        EditDogComponent,
        InscriptionsComponent,
        InscriptionListItemComponent,
        FilterPipe,
        RazzaFilterPipe,
        LocaleDatePipe,
        GroupFilterPipe,
        ColumnFilterPipe,
        DataGiornoFilterPipe,
        RazzaCodEnciFilterPipe,
        EventDetailsLightComponent,
        EventDetailsResultComponent,
        EventDetailsModalsComponent,
        ImageUploadWithPreviewComponent,
        PasswordOkComponent,
        CountDownComponent,
        RequisitiComponent,
        RequisitiItemComponent,
        CallbackFromAuthComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['/api', environment.issuer],
                sendAccessToken: true
            }
        }),
        ToastrModule.forRoot(),
        RouterModule.forRoot(
            routes, { scrollPositionRestoration: 'enabled' }
        ),
        TranslocoModule,
        TranslocoLocaleModule.init({
            localeConfig: {
                global: {
                    date: {
                        dateStyle: 'medium'
                    }
                },
                localeBased: {}
            }
        }),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        PopoverModule.forRoot(),
        CommonModule,
        //SCP PER DEV: 'AIzaSyADb6EON3nuR8vhfG9zEdwdZTcZRq-_5hA'
        // PROD 'AIzaSyCstwinB9HsRT98NRoQToeOQ7DTBemteEo'
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCstwinB9HsRT98NRoQToeOQ7DTBemteEo'
        }),
        CollapseModule.forRoot(),
        NgSelectModule,
        AngularDraggableModule
    ],
    providers: [
        ApiService,
        {
            provide: APP_INITIALIZER, multi: true,
            useFactory: preloadLanguages,
            deps: [ApiService, TranslocoService, ModalService]
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            })
        },
        AuthGuard,
        { provide: LOCALE_ID, useValue: 'it-IT' },
        translocoLoader,
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                reRenderOnLangChange: true,
                prodMode: environment.production
            } as TranslocoConfig
        }
    ],
    exports: [
        GroupFilterPipe,
        LocaleDatePipe,
        FilterPipe,
        RazzaFilterPipe,
        ColumnFilterPipe,
        DataGiornoFilterPipe,
        RazzaCodEnciFilterPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}
