import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { VERSION } from './environments/version';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}
if (environment.enableSentry) {
  Sentry.init({
    dsn: "https://cc7f49a0294d6485e2ec83d86eb2c7cb@sentry.scponline.it/17",
    integrations: [],
    release: VERSION.version
  });
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
