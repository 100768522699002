import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'az-confemailok',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './confemailok.component.html'
})
export class ConfEmailOkComponent implements OnInit {

    constructor(
        private oauthService: AuthService
    ) {
    }

    public async ngOnInit() {
    }

    public login() {
        this.oauthService.initAuth();
    }
}