import { Injectable } from '@angular/core';
import { ApiService } from './app.service';
import { BehaviorSubject, Observable, Subscription, ReplaySubject, Subject, forkJoin, of } from 'rxjs';
import { tap, filter, switchMap, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import * as Sentry from "@sentry/angular";
import { AuthService } from './auth/auth.service';
@Injectable({
    providedIn: 'root'
})
export class ContextService {

    private _isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isAuthenticated$: Observable<boolean> = this._isAuthenticatedSubject.asObservable();

    private _currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public currentUser$: Observable<any> = this._currentUserSubject.asObservable();

    private _currentRolesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public currentRoles$: Observable<any[]> = this._currentRolesSubject.asObservable();

    private _currentOwnerSubject = new ReplaySubject<any>(1); //ReplaySubject + come BehaviourSubject, ma non ematte alcun valore di default
    public currentOwner$: Observable<any> = this._currentOwnerSubject.asObservable();

    private subscription: Subscription = new Subscription();

    public currentLang: string = this.translocoService.getActiveLang();
    public currentLang$: Observable<string> = this.translocoService.langChanges$;

    private _raggPopupSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public raggPopup$: Observable<string> = this._raggPopupSubject.asObservable();

    private _throwErrorMessage$ : Subject<string> = new Subject<string>();
    public throwErrorMessage$ : Observable<string> = this._throwErrorMessage$.asObservable();

    constructor(
        private oauthService: AuthService,
        private apiService: ApiService,
        private translocoService: TranslocoService) {
    }

    public initUserContext(): void {
        this._isAuthenticatedSubject.next(true);
        this.subscription.add(this.oauthService.getIsDoneLoadAuthSuccess().pipe(
            filter(result => {
                return result; 
            }),
            //first(),
            map(_ => {
                let user = this.oauthService.getUser();
                Sentry.setUser({ email: user.email, id: user.sub });
                if(user.error_impersonate) {
                    this._throwErrorMessage$.next(user.error_impersonate.toString());
                }
                this._currentUserSubject.next(user);
                return user;
            }),
            switchMap(user => {
                return forkJoin([
                    this.apiService.get("Users/GetGpRuoliByUserGuid", { userGuid: user.sub }).pipe(tap((roles) => {
                        this._currentRolesSubject.next(roles.dto);
                    })),
                    this.apiService.get("Users/GetOwnerByUserGuid", { userGuid: user.sub }).pipe(tap((detail) => {
                        if (detail.dto && detail.dto.IdNazione && detail.dto.IdNazione != 45) {
                            localStorage.setItem("activeLang", "en-GB");
                            this.translocoService.setActiveLang("en-GB");
                        }
                        if (detail.dto) {
                            user['isSospeso'] = detail.dto.IsSospeso;
                        }
                        this._currentOwnerSubject.next(detail.dto);
                    }))
                ]);
            })
        ).subscribe(() => {}));
    }

    public initGuestContext(): void {
        Sentry.setUser(null);
        this._isAuthenticatedSubject.next(false);
        this._currentUserSubject.next(null);
        this._currentOwnerSubject.next(null);
        this._currentRolesSubject.next([]);
    }

    public dispose() {
        this.subscription.unsubscribe();
    }

    public updateOwner(owner: any) {
        this._currentOwnerSubject.next(owner)
    }

    public openPop(tipo: string) {
        this._raggPopupSubject.next(tipo);
    }
}